@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap');

$bgColor: rgb(37, 41, 44);
$bgSecondary: #484B4F;
$bgDark: #34373B;
$textColor: #ffff;
$accentColor: #4FFD80;
$accentColorDimmed: #75ebbb;
$dcBlueColor: #5865F2;
$dcRedColor: #ED4245;

$containerWidth: 1200px;

$bpPhone: 900px;
$bpTablet: 1000px;
$bpDesktop: 1024px;

*::-webkit-scrollbar {
    display: none;
}

body {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    line-height: 1.5;
    margin: 2rem;
    color: $textColor;
    background-color: $bgColor;
}

a {
    text-decoration: none;
    color: $accentColor;
    transition: 0.2s;

    &:hover {
        color: $accentColorDimmed;
    }
}

.container {
    max-width: $containerWidth;
    margin: 0 auto;
}

.navbar {
    .nav-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    
        .logo img {
            height: 70px;
            width: 70px;
        }
    
        .buttons {
            font-size: 1.5rem;
            display: flex;

            .button {
                margin-left: 2rem;
                transition: 0.2s;

                a {
                    color: $textColor;
                }

                &:hover {
                    opacity: 0.7;
                }
            }

            .logout {
                color: $dcRedColor;
            }
        }
    }
}

.profile {
    .userbar {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-top: 5rem;

        .avatar {
            display: flex;
            img {
                border-radius: 50%;
                width: 130px;
                height: 130px;
                margin-right: 30px;

                @media (max-width: $bpPhone) {
                    width: 80px;
                    height: 80px;
                }
            }
        }

        .info {
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .row {
                width: 100%;
            }
        }

        .name {
            font-size: 3rem;
            font-weight: bold;
            margin: 0;
            height: 100%;
            white-space: nowrap;
            overflow: hidden;

            .tag {
                color:  gray;
            }

            @media (max-width: $bpPhone) {
                font-size: 1.8rem;
                max-width: 150px;
            }
        }


        .row {
            display: flex;
            align-items: baseline;
        }

        .float {
            display: flex;
            height: 100%;
        }


        .levelProgress {
            min-width: 200px;
            height: 40px;
            display: flex;
            align-items: center;

            @media (max-width: $bpPhone) {
                min-width: 100px;
                height: 20px;
            }

            .level {
                display: flex;
                align-items: center;
                width: auto;
                height: 100%;
                padding: 0 15px;
                background-color: #4FFD80;
                color: #34373B;
                border-radius: 10px;
                border: #34373B 3px solid;

                p {
                    margin: 0;
                    font-size: 1.5rem;

                    @media (max-width: $bpPhone) {
                        font-size: 1.2rem;
                    }
                }

                @media (max-width: $bpPhone) {
                    padding: 5px 10px;
                }
            }

            .progress {
                max-width: 150px;
                background-color: #4a7556;
                border-radius: 0px 10px 10px 0px;
                color: #34373B;
                width: 100%;
                height: 65%;

                .bar {
                    height: 100%;
                    background-color: #8fffad;
                    border-radius: 0px 10px 10px 0px;
                    color: #34373B;
                }
            }
        }

        .badges {
            height: 100%;
            margin-left: 10px;

            @media (max-width: $bpPhone) {
                display: none;
            }

            .badge {
                display: inline;
                position: relative;

                img {
                    height: 35px;
                    margin: 0px 10px;
                }

                .popover {
                    opacity: 0;
                    visibility: hidden;
                    position: absolute;
                    left: -25px;
                    transform: translate(0, -80px);
                    background-color: $bgSecondary;
                    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
                    border-radius: 5px;
                    width: 100px;
                    height: 30px;
                    text-align: center;

                    .name {
                        font-size: 1rem;
                        font-weight: bold;
                        transform: translateY(+2px);
                    }
                }

                &:hover {
                    .popover {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }

        .addButton {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 20px;
            border-radius: 10px;
            background-color: $dcBlueColor;
            font-size: large;
            color: $textColor;
            transform: translateY(-30px);

            & img {
                height: 25px;
                width: 25px;
                margin-right: 10px;
            }

            &:hover {
                opacity: 0.8;
            }

            @media (max-width: $bpPhone) {
                padding: 10px;

                *:not(img){
                    display: none;
                }

                img {
                    margin: 0;
                }
            }
        }
    }

    .stats {
        margin-top: 5rem;
        background-color: $bgDark;
        border-radius: 10px;
        padding: 0 10px;
        display: flex;
        flex-wrap: nowrap;
        overflow: scroll;
        justify-content: space-around;

        @media (max-width: $bpPhone) {
            margin-top: 2rem;
            flex-wrap: wrap;
            padding: 0 2px;
            justify-content: center;
        }


        .item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            min-width: 100px;
            height: 100%;
            padding: 20px;
            border-radius: 10px;
            background-color: $bgSecondary;
            color: $textColor;
            margin: 20px 10px;
            transition: 0.2s;

            @media (max-width: $bpPhone) {
                min-width: 50px;
                max-width: 65px;
                margin: 5px;
            }

            @media (min-width: 550px) {
                min-width: 70px;
                max-width: 105px;
            }

            .value {
                font-size: 2rem;
                width: 100%;
                font-weight: bold;
                overflow: hidden;
                text-align: center;
                text-overflow: ellipsis;
            }

            .icon {
                font-size: 2rem;
                font-weight: bold;
            }

            .label {
                opacity: 0.5;
            }

            &:hover {
                transform: scale(1.07);
            }
        }
    }

    .error {
        font-size: 3rem;
        margin-top: 50px;
    }

    .customize_banner {
        width: 100%;
        margin: 30px 0;
        transition: 0.5s;

        &:hover {
            transform: skew(0deg, -1deg);
            content: url('./assets/customize_banner_hover.png');
        }

        @media (max-width: $bpPhone) {
            content: url('./assets/customize_banner_mobile.png');

            &:hover {
                transform: skew(0deg, 0deg);
                content: url('./assets/customize_banner_mobile_hover.png');
            }
        }
    }

    .games {
        position: relative;
        display: flex;
        max-width: $containerWidth;
        overflow: visible;
        margin-top: 20px;
        border-radius: 10px;    
        flex-wrap: wrap;   
        justify-content: center; 

        @media (max-width: $bpPhone) {
            flex-direction: column;
            margin-top: 0;
            overflow: hidden;
        }

        .board {
            padding: 10px;
            margin-right: 10px;
            margin: 5px;
            background-color: $bgDark;
            width: 370px;

            @media (max-width: $bpPhone) {
                display: flex;
                flex-direction: column;
                width: 95%;
                margin-right: 0;
                margin-bottom: 50px;
            }

            img {
                width: 370px;
                height: auto;

                @media (max-width: $bpPhone) {
                    width: 90%;
                    margin: 0 auto;
                    margin-bottom: 10px;
                }
            }

            .stats{
                display: flex;
                flex-direction: row;
                margin: 0;
                padding: 0;

                .stat {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: $bgSecondary;
                    margin: 5px;
                    padding: 5px;
                    border-radius: 5px;
                    height: 40px;
                    width: 100%;

                    .icon {
                        margin-right: 10px;
                        transform: translateY(3px);
                    }

                    .value {
                        width: auto;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        text-align: center;
                    }
                }
            }
        }

        .btn-container {
            width: 100vw;
            display: flex;
            justify-content: center;
        }

        button.loadMore {
            background-color: $accentColor;
            padding: 15px 30px;
            border-radius: 5px;    
            border: 0;
            font-weight: bold;
            font-size: 1rem;
            margin-top: 30px;
            transition: 0.2s;

            &:hover {
                opacity: 0.8;
            }
        }
    }   
}

.settings {
    .content {
        width: 100%;
        justify-content: start;
        display: flex;
        margin-top: 50px;
    }

    .categories {
        max-width: 25%;
        border-right: 1px solid gray;
        padding: 0;
        padding-right: 20px;

        li {
            background-color: $bgDark;
            padding: 15px;
            border-radius: 10px;
            list-style: none;
            margin: 15px 0;
            color: $textColor;
            transition: 0.2s;

            &.active {
                background-color: $accentColor;
                color: black;
            }

            &.danger {
                background-color: $dcRedColor;
                text-align: center;
            }

            @media (max-width: $bpPhone) {
                &.mobileNotActive {
                    background-color: $bgDark;
                    color: $textColor;
                }
            }    

            &:hover {
                background-color: $accentColorDimmed;
                color: black;
            }
        }

        @media(max-width: $bpPhone) {
            display: none;
        }

        &.mobile-categories {
            @media (max-width: $bpPhone) {
                display: block;
                min-width: 100%;
                border: none;
            }
        }

        @media(min-width: $bpPhone) {
            min-width: none;
            max-width: 25%;
            border: none;
        }
    }

    .panel {
        max-width: 80%;
        margin-left: 30px;
        margin-top: 10px;

        .title {
            color: $textColor;

            .backIcon {
                vertical-align: sub;
                margin-right: 15px;
                transform: translateY(-3px);

                @media(min-width: $bpPhone) {
                    display: none;
                }
            }
        }

        .pane {
            display: flex;
            margin-top: 50px;
            justify-content: space-between;

            @media (max-width: $bpTablet) {
                flex-direction: column;
                margin-top: 20px;
            }

            .preview img {
                width: 470px;
                height: auto;
                margin-left: 50px;
                border-radius: 20px;

                @media (max-width: $bpPhone) {
                    width: 100%;
                    margin: 0;
                    margin-top: 30px;
                }
            }

            .colorPicker .block-picker {
                width: 100% !important;
                height: 100% !important;
                background-color: transparent !important;

                input {
                    border: none !important;
                    background-color: $bgDark !important;
                    border-radius: 5px;
                    color: $textColor;
                    outline: none;
                }

            }

            &.soon {     
                width: 100%;
                flex-direction: column;;
                
                img.graphic {
                    height: 18rem;
                    margin-bottom: 30px;
                }

                p.desc {
                    text-align: center;
                }

                a.support {
                    margin: auto;
                    border-radius: 10px;
                    padding: 15px;
                    background-color: $accentColor;
                    width: 300px;
                    text-align: center;
                    font-size: 1.2rem;
                    color: black;
                    transition: 0.2s;

                    &:hover {
                        opacity: 0.8;
                    }
                }
            }
        }

        .pc-only {
            @media(max-width: $bpPhone) {
                display: none;
            }
        }
    }
}

.notFound {
    .content {
        margin-top: 50px;
        display: flex;

        img {
            margin-right: 50px;
        }
        
        h1 {
            color: $textColor;
            font-size: 3rem;
            margin-bottom: 20px;
        }
    }
}